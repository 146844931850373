//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildingsMessages {
 
  latest(data){
    return http.get( api.deparment_messages.last.endpoint, data );
  }

  message(data){
    return http.get( api.deparment_messages.get.endpoint, data );
  }

  create(data){
    return http.post( api.deparment_messages.create.endpoint, data);
  }
  
  delete(uuid){
    return http.delete( api.deparment_messages.delete.endpoint+uuid );
  }

}

export default new buildingsMessages();